import React from "react"
import Img from "gatsby-image"
import Confetti from "../../../assets/confetti.svg"
import Confetti2 from "../../../assets/confetti2.svg"

const Hero = ({ children, img, reverse }) => (
  <div className="container-lg py-8 md:py-16 lg:py-24 text-gray-800">
    <div className="grid grid-cols-1 lg:grid-cols-2 md:gap-4">
      {reverse ? (
        <>
           <div className="relative lg:mr-16 lg:-ml-12 mb-12 lg:mb-0">
            <Confetti className="absolute top-0 left-0 -mr-20 -mt-24 confetti" />
            <Confetti2
              className="absolute bottom-0 left-0 -mb-24 confetti"
              style={{ zIndex: 1 }}
            />
            {img ? (
              <Img
                fluid={img}
                alt="Two men sitting down talking about a coding issue"
                className="relative sm:-mx-2 w-full h-full rounded object-cover bg-gray-400"
              />
            ) : (
              <div className="relative sm:-mx-2 w-full h-full rounded object-cover bg-gray-400" />
            )}
          </div>
          <div className="md:-ml-6 relative ">{children}</div>
       
        </>
      ) : (
        <>
          <div className="md:-mr-6">{children}</div>
          <div className="relative lg:ml-16 lg:-mr-12 mt-8 lg:mt-0">
            <Confetti className="absolute top-0 left-0 -ml-20 -mt-24 confetti" />
            <Confetti2
              className="absolute bottom-0 right-0 -mb-24 confetti"
              style={{ zIndex: 1 }}
            />
            {img ? (
              <Img
                fluid={img}
                alt="Two men sitting down talking about a coding issue"
                className="relative sm:-mx-2 w-full h-full rounded object-cover bg-gray-400"
              />
            ) : (
              <div className="relative sm:-mx-2 w-full h-full rounded object-cover bg-gray-400" />
            )}
          </div>
        </>
      )}
    </div>
  </div>
)

export default Hero
