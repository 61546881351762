import React from "react"
import Layout from "../../components/root/Layout"
import Seo from "../../components/root/Seo"
import Hero from "../../components/modules/Hero"
import Card from "../../components/elements/Card"
import CheckCircle from "../../../assets/heroicons-0.4.1/optimized/solid/check-circle.svg"
import EmailForm from "../../components/elements/MenteeEmailForm"
import ConfettiTime from "../../components/elements/ConfettiTime"
import Newsletter from "../../components/elements/Newsletter"

export default function Mentees({ data, location }) {
  const card = {
    type: data.mentee.data.role.text.toUpperCase(),
    headline: data.mentee.data.description.text,
    list: Object.keys(data.mentee.data.benefits[0]).map(
      key => data.mentee.data.benefits[0][key].text
    ),
    pill: "pill-secondary",
    btn: "btn-secondary ",
    link: "/mentees",
  }
  const { type, pill, headline, list } = card
  const {
    card_desc,
    desc,
    tag_line,
    title_cta,
    title_part_one,
    title_part_two,
  } = data.prismicMenteesPage.data
  return (
    <>
      <ConfettiTime location={location} />
      <Layout>
        <Seo title="Mentees" />
        {/* <div className="w-full bg-yellow-400 p-2 text-center font-bold"> Applications are now closed until the 1st of March 2021.</div> */}
        <Hero
          reverse={true}
          img={
            data.prismicMenteesPage.data.hero_image.localFile.childImageSharp
              .fluid
          }
        >
          <h2 className="text-4xl md:text-5xl font-bold mb-3 leading-none">
            {title_part_one.text}{" "}
            <span className="text-brand-green">{title_part_two.text}</span>.
          </h2>
          <p className="text-xl">{desc.text}</p>
        </Hero>
        <div className="bg-brand-blue text-white md:mb-32">
          <div className="container-lg py-8 md:py-16 lg:py-24">
            <div className="grid grid-cols-1 md:grid-cols-2">
              <p className="text-xl mb-5 md:mb-0 md:text-2xl md:px-8">
                {card_desc.text}
              </p>
              <div className="md:-mb-48">
                <Card>
                  <div class={`${pill} mr-2 inline-block mb-3`}>{type}</div>
                  <h4 className="text-4xl leading-tight md:text-5xl font-bold mb-5">
                    {headline}
                  </h4>
                  <ul className="">
                    {list.map(item => (
                      <li>
                        <div className="grid grid-cols-9 mb-3">
                          <CheckCircle
                            className={`${
                              type === "MENTEE"
                                ? "text-brand-green"
                                : "text-brand-blue"
                            } glyph-md col-span-1`}
                          />
                          <p className="col-span-8 mt-1">{item}</p>
                        </div>
                      </li>
                    ))}
                  </ul>
                </Card>
              </div>
            </div>
          </div>
        </div>
        <div className="container py-8 md:pt-0 md:pb-16 ">
          {/* <h4 className="text-2xl md:text-3xl font-medium pt-8">
            {title_cta.text}
          </h4>
          <h5 className="text-4xl md:text-5xl font-bold">{tag_line.text}</h5> */}
          {/* <EmailForm /> */}

          <h4 className="text-2xl md:text-3xl font-medium pt-8">
            Applications are open for one week every three months.
          </h4>
          <h5 className="text-4xl md:text-5xl font-bold mb-2">{tag_line.text}</h5>
          <a href="https://app.archup.co.uk/login" className="btn text-center text-2xl">Sign Up</a>
        </div>
      </Layout>
    </>
  )
}

export const pageQuery = graphql`
  {
    mentee: prismicRoleType(data: { role: { text: { eq: "Mentee" } } }) {
      data {
        benefits {
          first {
            text
          }
          second {
            text
          }
          third {
            text
          }
        }
        description {
          text
        }
        role {
          text
        }
      }
    }
    prismicMenteesPage {
      data {
        card_desc {
          text
        }
        desc {
          text
        }
        tag_line {
          text
        }
        title_cta {
          text
        }
        title_part_one {
          text
        }
        title_part_two {
          text
        }
        hero_image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 780) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      }
    }
  }
`
